<template>
  <div>
    <v-dialog
      v-model="showForm"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
      @keydown.esc="close"
    >
      <v-form ref="form2" v-model="valid" lazy-validation>
        <v-card flat>
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="2" md="2" lg="2">
                {{ formTitle }}
              </v-col>
              <v-col cols="12" sm="7" md="7" lg="7">
                <v-tabs v-model="tab0">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab key="1" href="#1"> Well Master Data </v-tab>
                  <v-tab key="2" href="#2" v-if="editedItem.id != -1">
                    Well Status
                  </v-tab>
                  <v-tab key="6" href="#6" v-if="editedItem.id != -1">
                    WII Tests
                  </v-tab>
                  <v-tab key="5" href="#5" v-if="editedItem.id != -1">
                    Journal Events
                    <v-badge
                      v-if="
                        events.filter((elm) => elm.statut_id == 0).length > 0
                      "
                      color="orange"
                      :content="
                        events.filter((elm) => elm.statut_id == 0).length
                      "
                      overlap
                    >
                      <v-icon>mdi-bell-alert</v-icon>
                    </v-badge>
                  </v-tab>
                  <v-tab key="7" href="#7" v-if="editedItem.id != -1">
                    Data Production
                  </v-tab>
                  <v-tab key="8" href="#8" v-if="editedItem.id != -1">
                    Work Over
                  </v-tab>
                  <v-tab key="3" v-if="editedItem.id != -1" href="#3">
                    Attached Files
                  </v-tab>
                </v-tabs>
              </v-col>

              <v-col cols="12" sm="3" md="3" lg="3">
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-if="editedItem.id > 0"
                      @click.stop="drawer = !drawer"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-notebook-edit-outline </v-icon>
                      Well Book
                    </v-btn>
                  </template>
                  <span>Well Book</span>
                </v-tooltip>

                <v-btn
                  color="blue darken-1"
                  text
                  @click="validate"
                  v-if="
                    valider && editedItem.statut_id == '1' && editedItem.id > 0
                  "
                  :disabled="change > 2"
                >
                  Validate
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :disabled="change == 2"
                  v-if="modify"
                >
                  Save
                </v-btn>
                <v-btn color="blue darken-1" text @click="close">
                  {{ change == 2 ? "Close" : "Cancel" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle>
            Type : {{ editedItem.welltype }}
            <v-spacer></v-spacer>
            Field : {{ editedItem.fieldname }}
            <v-spacer></v-spacer>
            Drilling Date: {{ dateam(editedItem.drillingdate) }}
          </v-card-subtitle>
          <v-progress-linear
            v-if="progress"
            indeterminate
            :size="30"
            :width="7"
            color="purple"
          ></v-progress-linear>

          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="8" md="8" lg="8">
                <v-tabs-items v-model="tab0">
                  <v-tab-item :value="'1'">
                    <v-tabs v-model="tab1" @change="tab_change">
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab key="1" href="#1"> Generale Information </v-tab>
                      <v-tab key="2" href="#2" v-if="editedItem.id != -1">
                        Formations
                      </v-tab>
                      <v-tab key="3" href="#3" v-if="editedItem.id != -1">
                        Tubulars
                      </v-tab>
                      <v-tab key="4" href="#4" v-if="editedItem.id != -1">
                        ANNULUS
                      </v-tab>
                      <v-tab key="5" href="#5" v-if="editedItem.id != -1">
                        Elements
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab1">
                      <v-tab-item :value="'1'">
                        <v-card flat>
                          <v-card-text>
                            <v-row dense>
                              <!-- Libelle -->
                              <v-col cols="12" sm="5" md="5">
                                <v-text-field
                                  autocomplete="off"
                                  outlined
                                  dense
                                  v-model="editedItem.wellname"
                                  label="Well Name"
                                  :rules="[
                                    (v) => !!v || 'Well Name required',
                                    (v) => lib || 'Well Name exist !!',
                                  ]"
                                  :loading="libv"
                                  :readonly="
                                    editedItem.statut_id > '1' ||
                                    !modify ||
                                    editedItem.testcount > 0
                                  "
                                  @input="lib_change"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2" md="2">
                                <v-select
                                  :items="[
                                    { id: '1', label: 'Drilling' },
                                    { id: '2', label: 'Operating' },
                                    { id: '3', label: 'P&A' },
                                  ]"
                                  v-model="editedItem.lifecycle"
                                  item-text="label"
                                  item-value="id"
                                  label="Life Cycle"
                                  :rules="[(v) => !!v || 'Life Cycle required']"
                                  outlined
                                  dense
                                  :readonly="
                                    editedItem.statut_id > '1' || !modify
                                  "
                                  @input="change++"
                                ></v-select>
                              </v-col>
                              <!-- TD -->
                              <v-col cols="12" sm="2" md="2">
                                <v-text-field
                                  autocomplete="off"
                                  type="number"
                                  outlined
                                  dense
                                  v-model.number="editedItem.td"
                                  :label="
                                    'Total Depth (' +
                                    $store.state.options[0].depth +
                                    ')'
                                  "
                                  :rules="[
                                    (v) => !!v || 'Total Depth required',
                                  ]"
                                  :readonly="
                                    editedItem.statut_id > '1' ||
                                    !modify ||
                                    editedItem.testcount > 0
                                  "
                                  hide-spin-buttons
                                  @input="change++"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!-- Life Cycle -->

                              <!-- Well Type -->
                              <v-col cols="12" sm="5" md="5">
                                <v-autocomplete
                                  :items="wells_types"
                                  v-model="editedItem.welltype_id"
                                  item-text="label"
                                  item-value="id"
                                  label="Well Type"
                                  :rules="[(v) => !!v || 'Well Type required']"
                                  outlined
                                  dense
                                  :readonly="
                                    editedItem.statut_id > '1' || !modify
                                  "
                                  @input="change++"
                                ></v-autocomplete>
                              </v-col>
                              <!-- Status -->
                              <v-col cols="12" sm="2" md="2">
                                <v-autocomplete
                                  :items="wells_status"
                                  v-model="editedItem.statut_id"
                                  item-text="label"
                                  item-value="id"
                                  label="Status"
                                  :rules="[(v) => !!v || 'Status required']"
                                  outlined
                                  dense
                                  :readonly="
                                    editedItem.statut_id > '1' || !modify
                                  "
                                >
                                </v-autocomplete>
                              </v-col>
                              <!-- Field-->
                              <v-col cols="12" sm="2" md="2">
                                <selecttree
                                  :items="fields"
                                  v-model="field_id"
                                  :value="field_id"
                                  :item_key="'id'"
                                  :item_text="'description'"
                                  :label="'Field'"
                                  :readonly="!modify"
                                  :dense="true"
                                  :key="st"
                                  :oblig="true"
                                  :select_type="'independent'"
                                  :selectable="false"
                                  :item_disabled="'locked'"
                                  :deletable_chips="false"
                                  :closeoncontentclick="false"
                                >
                                </selecttree>
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!-- Latitude -->
                              <v-col cols="12" sm="1" md="2">
                                <v-text-field
                                  autocomplete="off"
                                  dense
                                  type="number"
                                  v-model.number="editedItem.latitude"
                                  label="Latitude"
                                  :rules="[(v) => !!v || 'Latitude required']"
                                  :readonly="!modify"
                                  hide-spin-buttons
                                  @input="change++"
                                ></v-text-field>
                              </v-col>
                              <!-- Longitude -->
                              <v-col cols="12" sm="1" md="2">
                                <v-text-field
                                  autocomplete="off"
                                  dense
                                  type="number"
                                  v-model.number="editedItem.longitude"
                                  label="Longitude"
                                  :rules="[(v) => !!v || 'Longitude required']"
                                  :readonly="!modify"
                                  hide-spin-buttons
                                  @input="change++"
                                ></v-text-field>
                              </v-col>
                              <!-- Drilling Date -->
                              <v-col cols="12" sm="2" md="2">
                                <datepicker
                                  label="Drilling Date"
                                  v-model="editedItem.drillingdate"
                                  :rules="[
                                    (v) => !!v || 'Drilling Date required',
                                  ]"
                                  :date_max="today"
                                  :edit="modify"
                                  :clearable="false"
                                  :key="cs"
                                ></datepicker>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-select
                                  :items="flags_list"
                                  v-model="selectedflags"
                                  item-text="description"
                                  item-value="id"
                                  label="Flags"
                                  outlined
                                  multiple
                                  chips
                                  dense
                                  :readonly="
                                    editedItem.statut_id > '1' || !modify
                                  "
                                  @input="change++"
                                >
                                  <template v-slot:selection="{ item }">
                                    <!-- HTML that describe how select should render selected items -->
                                    <v-chip small :color="item.color">
                                      {{ item.description }}
                                    </v-chip>
                                  </template>
                                  <template v-slot:item="data">
                                    <v-icon
                                      v-if="isSelected(data.item)"
                                      color="primary"
                                      >mdi-check</v-icon
                                    >
                                    <v-chip
                                      :input-value="isSelected(data.item)"
                                      :color="data.item.color"
                                      small
                                      class="ma-1"
                                    >
                                      {{ data.item.description }}
                                    </v-chip>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>

                            <v-row>
                              <!-- Comment -->
                              <v-col cols="12" sm="10" md="10">
                                <v-text-field
                                  outlined
                                  dense
                                  v-model="editedItem.comment"
                                  label="Description"
                                  :readonly="!modify"
                                  @input="change++"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item :value="'2'">
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <listitems
                                  :list="
                                    formations_list.filter(
                                      (elm) => elm.active == 1
                                    )
                                  "
                                  :title="'Formations'"
                                  :headers="formations_headers"
                                  :showedit="false"
                                  :master="true"
                                  :add="
                                    formations_list.filter(
                                      (elm) => elm.active == 0
                                    ).length > 0 && editedItem.statut_id == '1'
                                  "
                                  :del="false"
                                  :key="frm_key"
                                  @rowselect="FormationSelect"
                                  :ipg="-1"
                                  :hdf="true"
                                  :exp_excel="editedItem.statut_id > '1'"
                                  :btn_update="editedItem.statut_id == '1'"
                                  @col_btn1_click="FormationOpen"
                                  @col_btn2_click="FormationDelete"
                                  @open="FormationOpen"
                                  @btn_update_click="OpenImportForm"
                                  :selitem="formation"
                                  :laoding="data_loading"
                                >
                                </listitems>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item :value="'3'">
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <listitems
                                  :list="tubulars_list"
                                  :title="'Tubulars'"
                                  :headers="tubulars_headers"
                                  :showedit="false"
                                  :master="true"
                                  :add="true"
                                  :del="false"
                                  :key="tbl_key"
                                  @rowselect="TubularSelect"
                                  @open="TubularOpen"
                                  :ipg="-1"
                                  :hdf="true"
                                  :selitem="tubular"
                                  @col_btn1_click="TubularOpen"
                                  @col_btn2_click="TubularDelete"
                                >
                                </listitems>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <listitems
                                  :list="sizes_list"
                                  :title="'Real corrosion data'"
                                  :headers="reel_cor"
                                  :showedit="false"
                                  :master="true"
                                  :add="false"
                                  :del="false"
                                  :key="tbl_key"
                                  :ipg="-1"
                                  :hdf="true"
                                  :selitem="tubular"
                                >
                                </listitems>
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                v-if="reel_cor_rest.length > 0"
                              >
                                <listitems
                                  :list="reel_cor_rest"
                                  :title="'Estimed corrosion data'"
                                  :headers="estim_cor"
                                  :showedit="false"
                                  :master="true"
                                  :add="false"
                                  :del="false"
                                  :key="tbl_key"
                                  :ipg="-1"
                                  :hdf="true"
                                  :selitem="tubular"
                                >
                                </listitems>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <listitems
                                  :list="field_cr_rate"
                                  :title="'Field Corrosion Data'"
                                  :headers="field_cor"
                                  :showedit="false"
                                  :master="true"
                                  :add="false"
                                  :del="false"
                                  :key="tbl_key"
                                  :ipg="-1"
                                  :hdf="true"
                                >
                                </listitems>
                              </v-col>
                            </v-row>
                          </v-card-text> </v-card
                      ></v-tab-item>
                      <v-tab-item :value="'4'">
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="6" md="12">
                                <listitems
                                  :list="annulus_list"
                                  :qUpdate="Qannulusupdate"
                                  :title="'Annulus'"
                                  :headers="annulus_headers"
                                  :Get_suf="'Wellstubular'"
                                  :showstd="true"
                                  :Update="editedItem.statut_id > 1"
                                  :master="false"
                                  :add="false"
                                  :del="false"
                                  :key="ann_key"
                                  @col_btn1_click="AnnulusOpen"
                                  :hdf="true"
                                >
                                </listitems>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item :value="'5'">
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="6" md="12">
                                <listitems
                                  :list="
                                    components_list.filter(
                                      (elm) => elm.compartment == null
                                    )
                                  "
                                  :title="'Components'"
                                  :headers="components_headers"
                                  :Get_suf="'WellsComponent'"
                                  :showstd="false"
                                  :showedit="false"
                                  :Update="
                                    component.component_id != 1 &&
                                    editedItem.statut_id > 1
                                  "
                                  :showexp="true"
                                  :exp_data="components_detail"
                                  :hdh_exp="false"
                                  :exp_key="exp_key"
                                  :exp_headers="components_headers"
                                  :val_exp="'nb_child'"
                                  :mes_exp="'Child Component'"
                                  :master="true"
                                  :del="false"
                                  @open="ComponentOpen"
                                  @col_btn1_click="ComponentEdit"
                                  @rowselect="SelectComponent"
                                  :ipg="-1"
                                  :add="true"
                                  :key="cmp_key"
                                  :hdf="true"
                                  @col_btn2_click="ComponentDelete"
                                >
                                </listitems>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-tab-item>
                  <v-tab-item :value="'2'">
                    <v-tabs v-model="tabs22">
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab key="1" href="#1"> Failures </v-tab>
                      <v-tab key="2" v-if="editedItem.id != -1" href="#2">
                        Actions
                      </v-tab>
                      <v-tab key="3" v-if="editedItem.id != -1" href="#3">
                        Differrals
                      </v-tab>
                      <v-tab key="4" v-if="editedItem.id != -1" href="#4">
                        Evaluations
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabs22">
                      <v-tab-item :value="'1'">
                        <listitems
                          :list="failures_list"
                          :headers="failures_headers"
                          :toolbar="false"
                          :key="kfail"
                          @col_btn1_click="FailureOpen"
                        >
                        </listitems>
                      </v-tab-item>
                      <v-tab-item :value="'4'">
                        <v-card max-width="800" flat>
                          <v-card flat>
                            <v-card-title
                              :class="
                                'pa-2 ' +
                                (editedItem.compliant == 1 ? 'green' : 'red')
                              "
                            >
                              <h3
                                class="text-h6 font-weight-light text-center grow"
                              >
                                Actual Evaluation
                              </h3>
                              <h3
                                :class="
                                  'font-weight-light text-center  grow ' +
                                  editedItem.integrity_status_color
                                "
                              >
                                {{ editedItem.integrity_status_name }}
                              </h3>
                            </v-card-title>

                            <v-col class="text-right">
                              <v-btn
                                absolute
                                top
                                color="blue darken-1"
                                class="ma-6"
                                v-if="
                                  valid_test &&
                                  wells_evaluations.findIndex(
                                    (elm) => elm.statut_id == 0
                                  ) == -1
                                "
                                @click.stop="open_eval"
                                fab
                                small
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                            <v-timeline>
                              <v-timeline-item
                                v-for="(eval1, index) in wells_evaluations"
                                :key="index"
                                :color="eval1.color"
                                small
                              >
                                <template v-slot:opposite>
                                  <h4>{{ eval1.eval_name }}</h4>
                                  <span
                                    :class="`headline font-weight-bold ${eval1.color}--text`"
                                    v-text="eval1.eval_date"
                                  ></span>
                                </template>
                                <div class="py-4">
                                  <h2
                                    :class="`headline font-weight-light mb-4 ${eval1.color}--text`"
                                  >
                                    {{ eval1.status_name }}
                                    <div>
                                      <v-btn
                                        color="blue darken-1"
                                        fab
                                        v-if="
                                          eval1.statut_id == 0 &&
                                          valid_test &&
                                          eval1.eval_id != 0 &&
                                          (eval1.eval_by ==
                                            $store.state.me.id ||
                                            $store.state.isadmin)
                                        "
                                        @click.stop="open_eval(eval1, index)"
                                        class="mx-2"
                                        x-small
                                      >
                                        <v-icon>mdi-pencil</v-icon>
                                      </v-btn>
                                      <v-btn
                                        color="blue darken-1"
                                        fab
                                        v-if="
                                          eval1.statut_id == 0 &&
                                          valid_test &&
                                          wells_evaluations.length > 1 &&
                                          eval1.eval_id != 0 &&
                                          (eval1.eval_by ==
                                            $store.state.me.id ||
                                            $store.state.isadmin)
                                        "
                                        @click.stop="del_eval(eval1, index)"
                                        x-small
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </div>
                                  </h2>

                                  <div>
                                    {{ eval1.comment }}
                                  </div>
                                </div>
                              </v-timeline-item>
                            </v-timeline>
                          </v-card>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                    <v-tabs-items v-model="tabs22"> </v-tabs-items>
                  </v-tab-item>
                  <v-tab-item :value="'5'">
                    <v-container>
                      <v-tabs v-model="tab5">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab key="1" href="#1"> Journals </v-tab>
                        <v-tab key="2" href="#2">
                          New Events

                          <v-badge
                            v-if="
                              events.filter((elm) => elm.statut_id == 0)
                                .length > 0
                            "
                            color="orange"
                            :content="
                              events.filter((elm) => elm.statut_id == 0).length
                            "
                            overlap
                          >
                            <v-icon>mdi-bell-alert</v-icon>
                          </v-badge>
                        </v-tab>
                      </v-tabs>
                      <v-tabs-items v-model="tab5">
                        <v-tab-item :value="'1'">
                          <v-card flat>
                            <v-card-title>
                              <v-row>
                                <v-col cols="12" sm="6" md="6">
                                  <v-select
                                    :items="journals"
                                    v-model="selectedjournals"
                                    item-text="label"
                                    item-value="id"
                                    item-color="color"
                                    label="Journals"
                                    outlined
                                    multiple
                                    chips
                                    dense
                                    @input="journal_change"
                                  >
                                    <template v-slot:selection="{ item }">
                                      <!-- HTML that describe how select should render selected items -->
                                      <v-chip small :color="item.color">
                                        {{ item.label }}
                                      </v-chip>
                                    </template>
                                  </v-select>
                                </v-col>
                              </v-row>
                            </v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <listitems
                                    :list="events_list"
                                    :title="'Events'"
                                    :headers="events_headers"
                                    :showstd="true"
                                    :master="true"
                                    :add="false"
                                    :del="false"
                                    :key="event_key"
                                  >
                                  </listitems>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item :value="'2'">
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                                v-model="showall"
                                label="Show processed events"
                              ></v-checkbox>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <eventform
                                :title="'Workover'"
                                :color="'gray'"
                                :items="
                                  events.filter(
                                    (elm) =>
                                      elm.cat_id == '1' &&
                                      (elm.statut_id == 0 || showall)
                                  )
                                "
                                :journals="journals"
                                @well_change="well_change"
                              ></eventform>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <eventform
                                :title="'Drilling'"
                                :color="'green'"
                                :items="
                                  events.filter(
                                    (elm) =>
                                      elm.cat_id == '2' &&
                                      (elm.statut_id == 0 || showall)
                                  )
                                "
                                :journals="journals"
                                @well_change="well_change"
                              ></eventform>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <eventform
                                :title="'Rigless'"
                                :color="'purple'"
                                :items="
                                  events.filter(
                                    (elm) =>
                                      elm.cat_id == '3' &&
                                      (elm.statut_id == 0 || showall)
                                  )
                                "
                                :journals="journals"
                                @well_change="well_change"
                              ></eventform>
                            </v-col>
                          </v-row>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item :value="'6'">
                    <testlistform
                      :well="editedItem"
                      :data_loading="data_loading"
                      :tool_list="tool_list"
                      :level_list_cor="level_list_cor"
                      :level_list_cem="level_list_cem"
                      :teststypes_list="teststypes_list"
                      :defaults_list="defaults_list"
                      :users="users"
                      :fluids_elements="fluids_elements"
                      :fluids="fluids"
                      :actual_corrosions="actual_corrosions"
                      :actual_cements="actual_cements"
                      @tab_change="tab2_change"
                      @well_change="well_change"
                      @corrosion_test="corrosion_test_change"
                      @cement_test="cement_test_change"
                      @tab11_change="tab11_change"
                      :chart_id="'MyChart2'"
                      :show_out="false"
                      :key="tf"
                    ></testlistform>
                  </v-tab-item>
                  <v-tab-item :value="'7'">
                    <v-card flat>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <listitems
                              :list="productions_list"
                              :title="'Data Production'"
                              :headers="productions_headers"
                              :qDelete="Qproductiondelete"
                              :showedit="false"
                              :master="true"
                              :add="true"
                              :del="true"
                              :del_disable="production.index > 0"
                              :key="prd_key"
                              :ipg="-1"
                              :hdf="true"
                              @open="OpenProd"
                              @rowselect="SelectProd"
                              @col_btn1_click="OpenProd"
                              :exp_excel="true"
                              :selitem="production"
                            >
                            </listitems>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'3'">
                    <filelist
                      :item="editedItem"
                      :list="docs_list"
                      :isform="false"
                      :key="fdocl"
                      :editer="modify"
                      :auth="auth"
                      :well_id="parseInt(editedItem.id)"
                    >
                    </filelist>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>

              <v-col cols="12" sm="4" md="4" lg="4" v-if="editedItem.id > 0">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="1"
                    md="4"
                    v-if="tab0 == 6 && tab2 != '9'"
                  >
                    <v-checkbox
                      v-model="cem_log"
                      label="Show Cement Log"
                      @change="ksvg1++"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="1"
                    md="4"
                    v-if="tab0 == 6 && tab2 != '2'"
                  >
                    <v-checkbox
                      v-model="cor_log"
                      label="Show Corrosion Log"
                      @change="ksvg1++"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <wellsubsurface
                      :formations="
                        formations_list.filter((elm) => elm.active == 1)
                      "
                      :csgs="tab1 != '2' ? tubulars_list : null"
                      :actual_corrosions="
                        (tab0 == '6' && tab2 == '2' && tab11 == '1') || cor_log
                          ? actual_corrosions
                          : null
                      "
                      :actual_cements="
                        (tab0 == '6' && tab2 == '9' && tab11 == '1') || cem_log
                          ? actual_cements
                          : null
                      "
                      :corrosion_test="
                        (corrosion_test.statut_id == 6 ||
                          corrosion_test.statut_id == 7) &&
                        tab0 == '6' &&
                        tab2 == '2' &&
                        tab11 == '2'
                          ? corrosion_test
                          : null
                      "
                      :cement_test="
                        (cement_test.statut_id == 6 ||
                          cement_test.statut_id == 7) &&
                        tab0 == '6' &&
                        tab2 == '9' &&
                        tab11 == '2'
                          ? cement_test
                          : null
                      "
                      :unit="$store.state.options[0].depth"
                      :well="editedItem"
                      :key="ksvg1"
                      v-if="(tab1 != '1' && tab1 != '5') || tab0 == '6'"
                    ></wellsubsurface>

                    <component_svg
                      :well="well_svg"
                      :dialogform="dialog_svg"
                      :key="ksvg2"
                      log="main2"
                      :zoom="zoom"
                      :ComponentShow="ComponentShow"
                      v-if="tab0 == '1' && (tab1 == 1 || tab1 == 5)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- Well Book -->
            <v-navigation-drawer
              v-model="drawer"
              absolute
              temporary
              right
              :width="drawer ? 800 : 0"
            >
              <wellbook
                :title="'Well Book'"
                :comments="comments"
                :document_type="0"
                :doc_id="parseInt(editedItem.id)"
                :key="kwb"
              ></wellbook>
            </v-navigation-drawer>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>

    <template>
      <confirmdialog ref="confirm" />
    </template>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showimage"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card height="700px">
          <v-img :src="images + editedItem.url" height="600px"></v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="showimage = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <formationform
      :showform="!isFormationClose"
      :list="formations_list"
      :modify="true"
      :item="formation"
      :well="editedItem"
      :depth_unit="$store.state.options[0].depth"
      :pressure_unit="$store.state.options[0].pressure"
      @close="isFormationClose = true"
      :fluids="fluids"
      @change="well_change"
      :key="fkey"
    ></formationform>

    <tubularform
      :showform="!isTubularClose"
      :list="tubulars_list"
      :tubulars="tubulars"
      :apicasing="apicasing"
      :fluids="fluids"
      :modify="true"
      :item="tubular"
      :depth_unit="$store.state.options[0].depth"
      :annulus="annulus"
      :well="editedItem"
      @close="isTubularClose = true"
      @change="well_change"
      @add="tubular_add"
      :key="tkey"
    ></tubularform>
    <componentform
      :pressure_unit="$store.state.options[0].pressure"
      :depth_unit="$store.state.options[0].depth_unit"
      :showform="!isComponentClose"
      :list="components_list"
      :welltubulars="tubulars_list"
      :suppliers="suppliers"
      :components_well="components_well"
      :well_id="parseInt(editedItem.id)"
      :modify="true"
      :item="component"
      :vsizes_list="vsizes_list"
      @close_cmp="close_cmp"
      @close="isComponentClose = true"
      @change="component_change"
      :key="tcomp"
    ></componentform>
    <componentselect
      :showform="!isComponentselectClose"
      :list="components_list"
      :welltubulars="tubulars_list"
      :components_well="components_well"
      :item="component"
      :key="tcompselect"
      @close="close_cmpselect"
    ></componentselect>
    <import_data
      :showForm="!isListClose"
      :title="title"
      :listheaders="listheaders"
      :items="formations_list"
      :init_list="formations_list"
      :item_key="'formationname'"
      :col_key="'Formation Name'"
      :item_id="'formation_id'"
      :width="width"
      :alert="alert"
      :type_alert="type_alert"
      :message="message"
      @close="isListClose = true"
      @save_import="save_import"
      :key="frml"
    ></import_data>
    <givenform
      :dialog="givendialog"
      :wellstypes="wellstypes"
      :welltype="editedItem.welltype_id"
      :failure="failure"
      :given="given"
      :given_action="given_action_list"
      @close_given="close_given"
      :key="kga"
    ></givenform>
    <integrityform
      :showForm="!isEvalClose"
      :well="editedItem"
      :list="wells_evaluations"
      :eval="evaluation"
      :evaluation_list="evaluation_list"
      :user_list="users"
      :key="keval"
      @close="isEvalClose = true"
      @eval_validate="eval_validate"
    >
    </integrityform>
    <productionform
      :showForm="!isProdClose"
      :item="production"
      :well="editedItem"
      :readonly="production.index > 0"
      :list="productions_list"
      :key="kprod"
      @close="isProdClose = true"
    >
    </productionform>
  </div>
</template>

<script>
let today = new Date().toISOString().slice(0, 10);
import WELL_FAILURES from "../graphql/Well/WELL_FAILURES.gql";
import GIVEN_ACTION from "../graphql/Tests/GIVEN_ACTION.gql";

import DELETE_PRODUCTION from "../graphql/Well/DELETE_PRODUCTION.gql";

import WELL_UPSERT from "../graphql/Well/WELL_UPSERT.gql";

import FORMATION_UPDATE from "../graphql/Well/FORMATION_UPDATE.gql";
import UPDATE_FORMATION from "../graphql/Well/UPDATE_FORMATION.gql";
import DELETE_EVAL from "../graphql/Well/DELETE_EVAL.gql";
import WELL from "../graphql/Well/WELL.gql";
import UPDATE_WELL from "../graphql/Well/UPDATE_WELL.gql";
import UPDATE_TUBULAR from "../graphql/Well/UPDATE_TUBULAR.gql";

import CREATE_COMPONENT from "../graphql/Well/CREATE_COMPONENT.gql";
import UPDATE_COMPONENT from "../graphql/Well/UPDATE_COMPONENT.gql";
import COMPONENT_LIST_DELETE from "../graphql/Well/COMPONENT_LIST_DELETE.gql";

import ACTUAL_SITUATION from "../graphql/Well/ACTUAL_SITUATION.gql";

import DELETE_TUBULAR from "../graphql/Well/DELETE_TUBULAR.gql";
import ALLCODE from "../graphql/Well/ALLCODE.gql";
import { dateam } from "@/utils/functions.js";
import { images } from "print/data.js";
import UPLOAD from "../graphql/UPLOAD.gql";

export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    selecttree: () => import("../components/SelectTree.vue"),
    filelist: () => import("../components/FileList.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    listitems: () => import("../components/ListItems.vue"),
    wellsubsurface: () => import("../components/svg/wellsubsurface.vue"),
    //  wellsurface: () => import("../components/svg/wellsurface.vue"),
    formationform: () => import("../components/FormationForm.vue"),
    testlistform: () => import("../components/Test_List_Form.vue"),
    tubularform: () => import("../components/TubularForm.vue"),
    componentform: () => import("../components/ComponentForm.vue"),
    componentselect: () => import("../components/ComponentFormSelect.vue"),
    component_svg: () => import("../components/ComponentSVG.vue"),
    import_data: () => import("../components/ImportData.vue"),
    wellbook: () => import("../components/WellBook.vue"),
    givenform: () => import("../components/GivenAction.vue"),
    integrityform: () => import("../components/IntegrityForm.vue"),
    productionform: () => import("../components/ProductionForm.vue"),
    eventform: () => import("../components/EventList.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    editer: Boolean,
    valider: Boolean,
    new_item_code: String,
    wells_status: Array,
    wells_types: Array,
    fields_list: Array,
    suppliers: Array,
    components_well: Array,
    tubulars: Array,
    apicasing: Array,
    fluids: Array,
    fluids_elements: Array,
    users: Array,
    level_list_cor: Array,
    level_list_cem: Array,
    tool_list: Array,
    vsizes_list: Array,
    teststypes_list: Array,
    evaluation_list: Array,
    flags_list: Array,
    defaults_list: Array,
    fields_cor_data: Array,
    journals: Array,
  },
  data: () => ({
    selectedjournals: [],
    wellstypes: [],
    given_action_list: [],
    actual_corrosions: [],
    actual_cements: [],
    given: {},
    failure: {},
    givendialog: false,
    kga: 6000,
    kfail: 60000,
    failures_list: [],
    valid_test: false,
    isComponentselectClose: true,

    isAPMClose: true,
    drawer: null,
    dialog_svg: false,
    well_svg: {},
    zoom: 2,
    title: "",
    isListClose: true,
    width: "1000px",
    alert: false,
    type_alert: "info",
    message: "",
    frml: 600,
    tab0: 1,
    tab1: 1,
    tab2: 1,
    tab5: 1,
    tabs22: 1,
    ii: 22,
    currentTab: 1,
    modify: false,
    lib: true,
    cem_log: false,
    cor_log: false,
    risk_log: false,
    lc: 0,
    tf: 100,
    cs: 250,
    st: 350,
    cement: {},
    corrosion: {},
    ann_key: 450,
    cmp_key: 550,
    frm_key: 650,
    tbl_key: 750,
    event_key: 15846,
    ksvg1: 950,
    ksvg2: 9500,
    cfkey: 1050,
    fkey: 1150,
    tkey: 1250,

    crkey: 1450,
    risk_key: 1550,
    tcomp: 1650,
    tcompselect: 16500,
    klist: 1750,
    kwb: 1450,
    elm_key: 1950,
    showall: false,
    libv: false,
    progress: false,
    isSelecting: false,
    isFormationClose: true,

    isTubularClose: true,
    isComponentClose: true,

    listheaders: [],
    events: [],
    showimage: false,
    fab: false,
    chosenFile: null,
    uploading: false,
    field_id: [],
    annulus: false,
    selectedflags: [],
    element: {},

    Test_Corrosion: {},
    failures_headers: [
      {
        slot: "col_btn1",
        selected: true,
        icon: "pen-cil",
        color: "indigo",
        width: "10",
        valeur: 0,
        sortable: false,
      },
      {
        text: "Item",
        value: "issue",
        selected: false,
      },
      {
        text: "Element",
        value: "component",
        selected: true,
      },
      {
        text: "Description",
        value: "discription",
        selected: true,
      },

      {
        text: "Failure rate(%)",
        value: "failure_taux",
        slot: "progress",
        align: "center",
        color: "color",
        width: 140,
        selected: true,
      },
      {
        text: "Risk Ranking",
        value: "risk",
        slot: "chip",
        color: "color",
        selected: true,
      },
      {
        text: "Measure Action",
        value: "action",
        selected: false,
      },
      {
        text: "Due Date",
        value: "repair_date",
        selected: true,
      },
      {
        text: "Code Action",
        value: "code_given_action",
        selected: true,
      },
      {
        text: "Given action",
        value: "given_action",
        selected: true,
      },
    ],
    annulus_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Annulus",
        value: "annulus",
        selected: true,
      },
      {
        text: "Init. MAASP",
        value: "maasp",
        selected: true,
        slot: "cur33",
        align: "end",
        edit: true,
        type: "number",
        sm: 3,
        md: 3,
      },
      {
        text: "Calc. MAASP",
        value: "maasp_calc",
        selected: true,
        slot: "cur33",
        align: "end",
        edit: true,
        type: "number",
        sm: 3,
        md: 3,
      },
      {
        text: "Estim. MAASP",
        value: "maasp_estim",
        selected: true,
        slot: "cur33",
        align: "end",
        edit: true,
        type: "number",
        sm: 3,
        md: 3,
      },
      {
        text: "MAOP",
        value: "maop",
        selected: true,
        edit: true,
        slot: "cur33",
        align: "end",
        type: "number",
        sm: 3,
        md: 3,
      },
      {
        text: "volume",
        value: "volume",
        selected: true,
        slot: "cur33",
        align: "end",
      },
      {
        text: "TOC",
        value: "topcement",
        selected: true,
        slot: "cur33",
        align: "end",
      },
      {
        text: "Fluid Type",
        value: "fluidtype",
        selected: true,
      },
      {
        text: "Fluid Density(ppg)",
        value: "fluid_density",
        selected: true,
      },
      {
        text: "Update",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-pencil",
        width: "10",
        sortable: false,
      },
    ],
    components_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        sortable: false,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
        edit: true,
        sortable: false,
      },
      {
        text: "Item",
        value: "label",
        selected: true,
        edit: true,
        sortable: false,
      },
      {
        text: "SN",
        value: "sn",
        selected: true,
        sortable: false,
      },
      {
        text: "Supplier",
        value: "supplier",
        selected: true,
        sortable: false,
      },
      {
        text: "Pressure Rating",
        value: "pressurerating",
        selected: true,
        slot: "cur33",
        align: "end",
        sortable: false,
      },
      {
        text: "Last Test Date",
        value: "lasttestdate",
        selected: true,
        slot: "date",
        sortable: false,
      },
      {
        text: "Item Cost",
        value: "itemcost",
        selected: true,
        slot: "cur33",
        align: "end",
        sortable: false,
      },
      {
        text: "Alr",
        value: "alr",
        selected: true,
        sortable: false,
      },
      {
        text: "Update",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-pencil",
        width: "10",
        sortable: false,
      },
      {
        text: "Delete",
        slot: "col_btn2",
        selected: true,
        icon: "mdi-delete",
        width: "10",

        sortable: false,
      },
    ],
    formations_headers: [
      {
        text: "Position",
        value: "no",
        selected: true,
        sortable: false,
        import: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
        sortable: false,
      },
      {
        text: "Formation Name",
        value: "formationname",
        selected: true,
        sortable: false,
        import: true,
      },
      {
        text: "Depth From",
        value: "depthfrom",
        selected: true,
        slot: "cur33",
        align: "end",
        sortable: false,
        import: true,
      },
      {
        text: "Depth To",
        value: "depthto",
        selected: true,
        slot: "cur33",
        align: "end",
        sortable: false,
      },
      {
        text: "Tickness",
        value: "depth",
        selected: true,
        slot: "cur33",
        align: "end",
        expire: true,
        sortable: false,
      },
      {
        text: "TVD",
        value: "tvd",
        selected: true,
        slot: "cur33",
        align: "end",
        expire: true,
        sortable: false,
        import: true,
      },
      {
        text: "Potentiel To Flow",
        value: "ispff",
        slot: "checkbox",
        selected: true,
        sortable: false,
        import: true,
      },
      {
        text: "Fluid Type",
        value: "fluidtype",
        selected: true,
        sortable: false,
      },
      {
        text: "Formation Pressure",
        value: "formationpr",
        selected: true,
        slot: "cur33",
        align: "end",
        sortable: false,
        import: true,
      },
      {
        text: "Surface Pressure",
        value: "surfacepr",
        selected: true,
        slot: "cur33",
        align: "end",
        sortable: false,
        import: true,
      },
      {
        text: "Update",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-pencil",
        width: "10",
        sortable: false,
      },
      {
        text: "Delete",
        slot: "col_btn2",
        selected: true,
        icon: "mdi-delete",
        width: "10",

        sortable: false,
      },
    ],
    events_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        sortable: false,
      },
      {
        text: "Categorie",
        value: "cat_name",
        selected: true,
        edit: true,
        sortable: false,
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        sortable: false,
      },
      {
        text: "Date",
        value: "comment_date",
        slot: "date",
        selected: true,
        sortable: false,
      },
      {
        text: "Journals",
        value: "journal_details",
        slot: "chips",
        selected: true,
        sortable: false,
      },
    ],
    tubulars_headers: [
      {
        text: "Position",
        value: "no",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
        width: "60",
        hiden: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Item",
        value: "item",
        selected: true,
        width: "100px",
        sortable: false,
        index: 0,
      },
      {
        text: "Size (in)",
        value: "api_in",
        selected: true,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbsizes",
        valeur: "1",
        width: "400",
        sortable: false,
        index: 0,
      },
      {
        text: "Size (mm)",
        value: "api_mm",
        align: "center",
        selected: false,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbsizes",
        valeur: "1",
        width: "200",
        sortable: false,
        index: 0,
      },
      {
        text: "Weight",
        value: "weights",
        align: "center",
        selected: false,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbweigths",
        valeur: "1",
        width: "180",
        sortable: false,
        index: 0,
      },
      {
        text: "Grade",
        value: "grades",
        selected: false,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbgrade",
        valeur: "1",
        width: "80",
        sortable: false,
        index: 0,
      },

      {
        text: "Depth From",
        value: "depthfrom",
        selected: true,
        slot: "cur33",
        align: "end",
        width: "100",
        dec: 0,
        sortable: false,
        index: 0,
      },
      {
        text: "Depth To",
        value: "depthto",
        selected: true,
        slot: "cur33",
        align: "end",
        width: "100",
        dec: 0,
        sortable: false,
        index: 0,
      },
      {
        text: "Depth",
        value: "depth",
        selected: false,
        slot: "cur33",
        align: "end",
        expire: true,
        width: "150",
        dec: 0,
        sortable: false,
        index: 0,
      },
      {
        text: "Top of Cement",
        value: "topcement",
        selected: true,
        slot: "cur33",
        align: "end",
        dec: 0,
        sortable: false,
        index: 0,
      },
      {
        text: "#Packer",
        value: "count_packers",
        selected: true,
        slot: "cur33",
        align: "end",
        dec: 0,
        sortable: false,
        index: 0,
      },
      {
        text: "Collapse Resist.",
        value: "collapse",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "120",
        sortable: false,
        index: 0,
      },
      {
        text: "IY",
        value: "iy",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "120",
        sortable: false,
        index: 0,
      },
      {
        text: "PBY",
        value: "pby",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "120",
        sortable: false,
        index: 0,
      },
      {
        text: "PBIY",
        value: "pbiy",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
        index: 0,
      },
      {
        text: "ID (in)",
        value: "id_in",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
        index: 0,
      },
      {
        text: "ID (mm)",
        value: "id_mm",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
        index: 0,
      },
      {
        text: "Capacity (mm)",
        value: "capacity",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
        index: 0,
      },
      {
        text: "open (mm)",
        value: "open",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
        index: 0,
      },
      {
        text: "Plugged (mm)",
        value: "plugged",
        slot: "cur33",
        align: "right",
        selected: false,
        width: "100",
        sortable: false,
        index: 0,
      },

      {
        text: "Details",
        value: "details",
        selected: false,
        sortable: false,
        index: 0,
      },
      {
        text: "Update",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-pencil",
        width: "10",
        sortable: false,
        index: 0,
      },
      {
        text: "Delete",
        slot: "col_btn2",
        selected: true,
        icon: "mdi-delete",
        width: "10",
        column1: "nbsizes",
        valeur1: 0,
        column2: "count_packers",
        valeur2: null,
        sortable: false,
      },
      {
        text: "Alert",
        slot: "col_btn3",
        selected: true,
        color: "orange",
        icon: "mdi-alert",
        width: "10",
        column1: "t_conform",
        valeur1: false,
        sortable: false,
        tooltip: "Size less than 1.3 min thickness",
      },
    ],
    reel_cor: [
      {
        text: "Position",
        value: "no",
        selected: true,
        sortable: false,
        index: 0,
      },

      {
        text: "Item",
        value: "item",
        selected: true,
        sortable: false,
        width: "100px",
        index: 0,
      },
      {
        text: "Size (in)",
        value: "api_in",
        selected: true,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbsizes",
        valeur: "1",
        width: "300",
        sortable: false,
        index: 0,
      },
      {
        text: "Age (Year)",
        value: "age",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Min.Thick. (in)",
        value: "t_min",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Init.Thick. (in)",
        value: "t_initial",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Cur.Thick. (in)",
        value: "t_current_reel",
        selected: true,
        sortable: false,
        expire: true,
        index: 0,
      },
      {
        text: "Corrosion (%)",
        value: "cor_reel",
        slot: "progress",
        selected: true,
        sortable: false,
        color: "color_reel",
        index: 0,
      },
      {
        text: "Cor. Rate (in/year)",
        value: "cr_reel",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Rem. life (year)",
        value: "rem_life_reel",
        selected: true,
        slot: "chip",
        sortable: false,
        index: 0,
      },
      {
        text: "Test Date",
        value: "cor_reel_testdate",
        slot: "date",
        selected: false,
        sortable: false,
        index: 0,
      },
      {
        text: "Alert",
        slot: "col_btn2",
        selected: true,
        color: "orange",
        icon: "mdi-alert",
        width: "10",
        column1: "t_conform_reel",
        valeur1: false,
        sortable: false,
        tooltip: "Below Minimum Allowable Thickness",
      },
    ],
    estim_cor: [
      {
        text: "Position",
        value: "no",
        selected: true,
        sortable: false,
        index: 0,
      },

      {
        text: "Item",
        value: "item",
        selected: true,
        sortable: false,
        width: "100px",
        index: 0,
      },
      {
        text: "Size (in)",
        value: "api_in",
        selected: true,
        slot: "text_icon",
        icon: "mdi-dots-horizontal",
        column: "nbsizes",
        valeur: "1",
        width: "300",
        sortable: false,
        index: 0,
      },
      {
        text: "Age (Year)",
        value: "age",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Min.Thick. (in)",
        value: "t_min",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Init.Thick. (in)",
        value: "t_initial",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Cur.Thick. (in)",
        value: "t_current_estim",
        selected: true,
        sortable: false,
        expire: true,
        index: 0,
      },
      {
        text: "Corrosion (%)",
        value: "cor_estim",
        slot: "progress",
        selected: true,
        sortable: false,
        color: "color_estim",
        index: 0,
      },

      {
        text: "Cor. Rate (in/year)",
        value: "cr_estim",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Rem. life (year)",
        value: "rem_life_estim",
        selected: true,
        sortable: false,
        slot: "chip",
        index: 0,
      },
      {
        text: "Test Date",
        value: "cor_estim_testdate",
        slot: "date",
        selected: false,
        sortable: false,
        index: 0,
      },
      {
        text: "Wells ref.",
        value: "wells_ref",
        selected: false,
        sortable: false,
        index: 0,
      },

      {
        text: "Alert",
        slot: "col_btn2",
        selected: true,
        color: "orange",
        icon: "mdi-alert",
        width: "10",
        column1: "t_conform_estim",
        valeur1: false,
        sortable: false,
        tooltip: "Below Minimum Allowable Thickness",
      },
    ],
    field_cor: [
      {
        text: "Position",
        value: "no",
        selected: true,
        sortable: false,
        index: 0,
      },

      {
        text: "Item",
        value: "item",
        selected: true,
        sortable: false,
        width: "100px",
        index: 0,
      },
      {
        text: "Size ",
        value: "api_in",
        selected: true,
        width: "300",
        sortable: false,
        index: 0,
      },
      {
        text: "Count",
        value: "nbsizes",
        selected: true,
        sortable: false,
        index: 0,
      },

      {
        text: "Logged",
        value: "nb_sizes_logged",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "# Tools",
        value: "nb_tools",
        selected: true,
        sortable: false,
        index: 0,
      },

      {
        text: "Min CR rate (in/year)",
        value: "field_cr_min",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Max CR rate (in/year)",
        value: "field_cr_max",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Avg CR rate (in/year)",
        value: "field_cr_avg",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Well CR rate (in/year)",
        value: "well_cr",
        selected: true,
        sortable: false,
        index: 0,
      },
      {
        text: "Eval (%)",
        value: "diff",
        slot: "progress",
        color: "color",
        selected: true,
        sortable: false,
        width: "100px",
        index: 0,
      },
      {
        text: "Evaluation",
        value: "eval",
        selected: true,
        sortable: false,
        width: "150px",
        index: 0,
      },
    ],
    productions_headers: [
      {
        text: "Date",
        value: "info_date",
        slot: "date",
        selected: true,
        sortable: false,
      },
      {
        text: "Surface Pressure (psi)",
        value: "surface_pressure",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },
      {
        text: "Bottomhole Pressure (psi)",
        value: "bottomhole_pressure",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },
      {
        text: "Oil flow Rate (bpd)",
        value: "oilflow_rate",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },
      {
        text: "Gas Flow Rate (cf/d)",
        value: "gasflow_rate",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },
      {
        text: "Water Flow Rate (bwpd)",
        value: "waterflow_rate",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },
      {
        text: "Surface Temp (F°)",
        value: "surface_temp",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },
      {
        text: "Bottom Hole Temp (F°)",
        value: "bottomhole_temp",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },

      {
        text: "Water Cut (%)",
        value: "water_cut",
        slot: "progress",

        selected: true,
        sortable: false,
      },
      {
        text: "Gas Oil Ratio (cf/bbl)",
        value: "gas_oil_ratio",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },
      {
        text: "Formation Pressure (psi)",
        value: "formation_pressure",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },
      {
        text: "Pressure Gradient (psi/ft)",
        value: "pressure_gradient",
        slot: "cur",
        align: "end",
        selected: true,
        sortable: false,
      },
      {
        text: "Update",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-pencil",
        width: "10",

        sortable: false,
        index: 0,
      },
    ],
    tests_list: [],
    cements_list: [],
    corrosions_list: [],
    fingers_list: [],
    tubulars_list: [],
    annulus_list: [],
    wells_evaluations: [],
    formations_list: [],
    productions_list: [],
    components_list: [],
    risks_list: [],
    apicasing_list: [],

    elements_list: [],
    components_detail: [],
    fdocl: 1000,
    exp_key: 2000,
    prd_key: 2540,
    change: 0,
    tabs: 3,
    x: "",
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    production: {},
    component: {},
    formation: {},
    tubular: {},
    cement_test: {},
    corrosion_test: {},
    evaluation: {},
    isEvalClose: true,
    isProdClose: true,
    keval: 1547,
    kprod: 2458,
    tab11: "1",
    events_list: [],
    defaultItem: {
      id: -1,
      created_at: today,
      docs: [],
      statut_id: "1",
    },
    loading: false,
    data_loading: false,
  }),

  computed: {
    reel_cor_rest() {
      let l = [];
      if (this.sizes_list)
        l = this.sizes_list.filter((elm) => elm.cor_reel == null);
      return l;
    },
    field_cr_rate() {
      let l = [];

      this.sizes_list.forEach((size) => {
        let cr_avg = null;
        let cr_min = null;
        let cr_max = null;
        let nbsizes = null;
        let nb_sizes_logged = null;
        let nb_tools = null;
        let evaluation = "";
        let diff = null;
        if (this.fields_cor_data) {
          let i = this.fields_cor_data.findIndex(
            (elm) =>
              elm.api_csc_id == size.api_csc_id &&
              elm.welltype_id == this.editedItem.welltype_id &&
              elm.field_id == this.editedItem.origin_field_id
          );
          if (i >= 0) {
            cr_avg = this.fields_cor_data[i].cr_avg;
            cr_min = this.fields_cor_data[i].cr_min;
            cr_max = this.fields_cor_data[i].cr_max;
            nbsizes = this.fields_cor_data[i].nbsizes;
            nb_sizes_logged = this.fields_cor_data[i].nb_sizes_logged;
            nb_tools = this.fields_cor_data[i].nb_tools;
          }
        }

        if (cr_avg >= 0 && size.cr_reel >= 0) {
          evaluation =
            size.cr_reel >= 0 && size.cr_reel == cr_avg
              ? "Withing average"
              : size.cr_reel && size.cr_reel > cr_avg
              ? "Higher than average"
              : size.cr_reel && size.cr_reel < cr_avg
              ? "Below average"
              : "";
          if (cr_avg == 0 && size.cr_reel == 0) diff = "100.00";
          else if (size.cr_reel)
            diff = parseFloat(
              (size.cr_reel / (cr_avg == 0 || cr_avg == null ? 1 : cr_avg)) *
                100
            ).toFixed(2);
        }
        l.push({
          id: size.api_csc_id,
          item: size.item,
          api_in: size.api_in,
          nbsizes: nbsizes,
          nb_sizes_logged: nb_sizes_logged,
          nb_tools: nb_tools,
          field_cr_avg: cr_avg,
          field_cr_min: cr_min,
          field_cr_max: cr_max,
          well_cr: size.cr_reel,
          diff: diff,
          color:
            size.cr_reel == cr_avg
              ? "green"
              : size.cr_reel > cr_avg
              ? "red"
              : "blue",
          eval: evaluation,
        });
      });
      return l;
    },
    comments: function () {
      return this.editedItem.comments ? this.editedItem.comments : [];
    },
    today() {
      return this.$store.state.today;
    },
    fields() {
      return this.fields_list;
    },

    formations() {
      let l = [];
      let i = this.fields_list.findIndex(
        (elm) => elm.id == this.field_id.toString()
      );
      if (i >= 0) l = this.fields_list[i].formations;
      return l;
    },
    dateam() {
      return dateam;
    },
    auth() {
      let a =
        this.prd_type == "02"
          ? "01002"
          : this.prd_type == "03"
          ? "01003"
          : this.prd_type == "04"
          ? "01004"
          : "01034";
      return a;
    },

    images() {
      return images;
    },
    sizes_list() {
      let l = [];
      this.tubulars_list.forEach((tubular) => {
        tubular.sizes_list.forEach((size) => {
          l.push(size);
        });
      });
      return l;
    },
    docs_list: function () {
      return this.editedItem.docs ? this.editedItem.docs : [];
    },

    Qannulusupdate() {
      return UPDATE_TUBULAR;
    },

    Qcomponentscreate() {
      return CREATE_COMPONENT;
    },
    Qcomponentsupdate() {
      return UPDATE_COMPONENT;
    },
    Qproductiondelete() {
      return DELETE_PRODUCTION;
    },
    itemchange() {
      this.editedItem.wellname;
      this.editedItem.comment;
      this.editedItem.lifecycle;
      this.editedItem.welltype_id;
      this.editedItem.statut_id;
      this.editedItem.latitude;
      this.editedItem.longitude;
      this.editedItem.drillingdate;
      this.field_id;
      return Date.now();
    },

    formTitle() {
      return this.editedItem.id < 0
        ? "New Well  "
        : "Well : " + this.editedItem.wellname;
    },
  },
  watch: {
    itemchange: {
      handler() {
        this.change++;
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    if (this.showForm) {
      this.valid_test =
        this.$store.state.auth.includes("03003") || this.$store.state.isadmin;
      if (this.item) {
        this.kwb++;
        if (this.item.id > 0) {
          this.editedItem = Object.assign({}, this.item);

          this.selectedflags = this.editedItem.wellsflags.map(
            (elm) => elm.flag_id
          );
          await this.get_failures();
          this.init_list();
          this.$nextTick(() => {
            this.change = 2;
          });
          this.cs++;
          this.kfail++;
          this.field_id.push(this.editedItem.field_id);
          let r = await this.$requette(ACTUAL_SITUATION, {
            well_id: [this.item.id],
          });
          if (r.ok) {
            this.actual_corrosions = r.data.actual_corrosions;
            this.actual_cements = r.data.actual_cements;

            this.ksvg1++;
          }
          if (!this.editedItem.url) this.editedItem.url = "no-image-icon";
        } else {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.created_at = this.$store.state.today;

          this.editedItem.code = this.new_item_code;
          if (this.wells_types)
            if (this.wells_types.length == 1)
              this.editedItem.welltype_id = this.wells_types[0].id;
        }
        this.st++;
      }
      this.formations_headers[11].hiden = this.editedItem.statut_id > "1";
      this.formations_headers[12].hiden = this.editedItem.statut_id > "1";
      this.annulus_headers[2].text =
        "Init. MAASP (" + this.$store.state.options[0].pressure + ")";
      this.annulus_headers[3].text =
        "Calc. MAASP (" + this.$store.state.options[0].pressure + ")";
      this.annulus_headers[4].text =
        "Estim. MAASP (" + this.$store.state.options[0].pressure + ")";
      this.annulus_headers[5].text =
        "MAOP (" + this.$store.state.options[0].pressure + ")";
      this.annulus_headers[6].text =
        "Volume (" + this.$store.state.options[0].volume + ")";
      this.annulus_headers[7].text =
        "Top of Cement (" + this.$store.state.options[0].depth + ")";
      this.components_headers[5].text =
        "Pressure Rating (" + this.$store.state.options[0].pressure + ")";
      this.components_headers[8].text =
        "ALR (" + this.$store.state.options[0].pressure + ")";
      this.components_headers[7].text =
        "Item Cost (" + this.$store.state.options[0].devise + ")";
      this.formations_headers[3].text =
        "Depth From (" + this.$store.state.options[0].depth + ")";
      this.formations_headers[4].text =
        "Depth To (" + this.$store.state.options[0].depth + ")";
      this.formations_headers[5].text =
        "Tickness (" + this.$store.state.options[0].depth + ")";
      this.formations_headers[6].text =
        "TVD (" + this.$store.state.options[0].depth + ")";
      this.formations_headers[9].text =
        "Formation Pressure (" + this.$store.state.options[0].pressure + ")";
      this.formations_headers[10].text =
        "Surface Pressure (" + this.$store.state.options[0].pressure + ")";

      this.tubulars_headers[7].text =
        "Depth From (" + this.$store.state.options[0].depth + ")";
      this.tubulars_headers[8].text =
        "Depth To (" + this.$store.state.options[0].depth + ")";
      this.tubulars_headers[9].text =
        "Depth (" + this.$store.state.options[0].depth + ")";
      this.tubulars_headers[10].text =
        "Top of Cement (" + this.$store.state.options[0].depth + ")";
      this.tubulars_headers[12].text =
        "Collapse Resist. (" + this.$store.state.options[0].pressure + ")";
      this.tubulars_headers[13].text =
        "IY (" + this.$store.state.options[0].pressure + ")";
      this.tubulars_headers[14].text =
        "PBY (" + this.$store.state.options[0].weight + ")";
      this.tubulars_headers[15].text =
        "PBIY (" + this.$store.state.options[0].pressure + ")";
      this.tubulars_headers[18].text =
        "Capacity (" + this.$store.state.options[0].volume + ")";
      this.tubulars_headers[19].text =
        "Open (" + this.$store.state.options[0].volume + ")";
      this.tubulars_headers[20].text =
        "Plugged (" + this.$store.state.options[0].volume + ")";
      this.modify =
        this.editer && (this.editedItem.id < 0 || this.$store.state.isadmin);
    }
  },

  methods: {
    journal_change() {
      this.events_list = [];
      if (this.selectedjournals.length > 0) {
        this.events.forEach((element) => {
          if (element.journals) {
            let j = element.journals.split(",");
            for (let index = 0; index < this.selectedjournals.length; index++) {
              const jrn = this.selectedjournals[index];
              if (j.includes(jrn)) {
                this.events_list.push(element);
                break;
              }
            }
          }
        });
      } else
        this.events_list = this.events.filter((elm) => elm.journals != null);
      this.event_key++;
    },
    tab11_change(tab) {
      this.tab11 = tab;

      this.ksvg1++;
    },
    isSelected(item) {
      return this.selectedflags.includes(item.id);
    },

    close_cmp() {
      this.isComponentClose = true;
      this.well_change();
      this.tcomp++;
    },
    close_given(item) {
      if (item) {
        this.getdata(parseInt(this.selected_well.id));
      }
      this.givendialog = false;
    },
    async getGivenAction() {
      let r = await this.$requette(
        GIVEN_ACTION,
        {
          failures: this.failure.item,
        },
        "no-cache"
      );
      if (r.ok) {
        this.wellstypes = r.data.wellstypes;
        this.given_action_list = r.data.given_action;
        if (r.data.getGivenAction[0]) this.given = r.data.getGivenAction[0];
        else
          this.given = {
            id: null,
            failures: this.failure.item,
            t_1: -1,
            t_2: -1,
            t_3: -1,
            t_4: -1,
            t_5: -1,
            t_6: -1,
            t_7: -1,
            t_8: -1,
          };
      }
    },
    async FailureOpen(item) {
      this.failure = item;
      await this.getGivenAction();
      this.kga++;
      this.givendialog = true;
    },
    async get_failures() {
      let r = await this.$requette(
        WELL_FAILURES,
        {
          WellID: parseInt(this.editedItem.id),
        },
        "no-cache"
      );
      if (r.ok) {
        this.failures_list = r.data.test_failures_single;
      }
    },

    corrosion_test_change(cor) {
      this.corrosion_test = cor;
      this.ksvg1++;
    },
    cement_test_change(cem) {
      this.cement_test = cem;
      this.ksvg1++;
    },
    tab2_change(tab) {
      this.tab2 = tab;
      this.ksvg1++;
    },
    OpenImportForm() {
      this.form_list = [];
      this.alert = false;
      this.title = "Import formations data ";
      this.listheaders = this.formations_headers.filter(
        (elm) => elm.import == true
      );
      this.width = "1000px";
      this.orientation = "p";
      this.isListClose = false;
      this.imp_si = true;
      this.frml++;
    },
    async eval_validate(e) {
      if (e) {
        this.editedItem.integrity_status_id = e.id;
        this.editedItem.integrity_status_name = e.name;
        this.editedItem.integrity_status_color = e.color;
        this.isEvalClose = true;
        if (this.wells_evaluations.length <= 1) {
          this.editedItem.compliant = 1;
          let v = {
            well: {
              id: this.editedItem.id,
              compliant: this.editedItem.compliant,
            },
          };
          await this.$maj(UPDATE_WELL, v);
        }
        this.$store.dispatch("Changed", true);
      }
      this.isEvalClose = true;
    },
    open_eval(e, index) {
      if (e.id) {
        this.evaluation = e;
        this.evaluation.index = index;
      } else this.evaluation = { id: -1 };
      this.isEvalClose = false;
      this.keval++;
    },
    OpenProd(item) {
      this.production = item;
      this.isProdClose = false;
      this.kprod++;
    },
    SelectProd(item) {
      this.production = item;
    },
    async del_eval(item, index) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Are you sûr to delete this evaluation?",
          { color: "orange darken-3" }
        )
      ) {
        let r = await this.$maj(DELETE_EVAL, { id: item.id });
        if (r.ok) {
          this.wells_evaluations.splice(index, 1);
          this.editedItem.integrity_status_id = this.wells_evaluations[0].id;
          this.editedItem.integrity_status_name =
            this.wells_evaluations[0].status_name;
          this.editedItem.integrity_status_color =
            this.wells_evaluations[0].color;
          this.$store.dispatch("Changed", true);
        }
      }
    },
    async save_import(items) {
      let ok = true;
      this.message = null;
      this.alert = false;
      let list = [];
      for (let index = 0; index < items.length; index++) {
        const element = items[index];

        if (element.id < 0) {
          this.message = "Invalid Formation Name" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (typeof element.depthfrom != "number" && ok) {
          this.message =
            "Invalid depth from Number" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (element.tvd != null && typeof element.tvd != "number" && ok) {
          this.message = "Invalid TVD Number" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (
          element.formationpr != null &&
          typeof element.formationpr != "number" &&
          ok
        ) {
          this.message =
            "Invalid Formation Pressure Number" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (
          element.surfacepr != null &&
          typeof element.surfacepr != "number" &&
          ok
        ) {
          this.message =
            "Invalid Surface Pressure Number" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (
          element.ispff != null &&
          element.ispff != true &&
          element.ispff != false &&
          ok
        ) {
          this.message =
            "Invalid Potentiel To Flow Value" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        list.push({
          id: element.id,
          depthfrom: element.depthfrom,
          tvd: element.tvd,
          formationpr: element.formationpr,
          surfacepr: element.surfacepr,
          pff: element.ispff == true ? 1 : 0,
        });
      }

      if (ok) {
        //TO DO import control TVD with mesured depth

        for (let index = 0; index < items.length; index++) {
          const element = items[index];
          if (element.depthfrom > this.editedItem.td) {
            this.message =
              "Error : formation start depth Max : " +
              this.editedItem.td +
              " - ligne " +
              (index + 1);
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }

          // let i = items.findIndex(
          //   (elm) =>
          //     (elm.depthfrom <= element.depthfrom &&
          //       elm.depthfrom > 0 &&
          //       elm.depthfrom != null) ||
          //     (elm.depthfrom >= element.depthfrom &&
          //       elm.depthfrom > 0 &&
          //       elm.depthfrom != null)
          // );
          // if (i >= 0 && element.depthfrom > 0) {
          //   this.message =
          //     "Error : not matching with next/previous formation start depth between formation " +
          //     element.formation_label +
          //     " and formation " +
          //     items[i].formation_label;

          //   this.type_alert = "error";
          //   this.alert = true;
          //   ok = false;
          //   break;
          // }
        }
      }
      if (ok) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Are you sûr to update formations data?",
            { color: "orange darken-3" }
          )
        ) {
          let v = {
            well_id: this.editedItem.id,
            list: list,
          };
          let r = await this.$maj(FORMATION_UPDATE, v);
          if (r.ok) {
            this.snackbar_text = "Formations data updated";
            this.snackbar_color = "success";
            this.snackbar = true;
            this.isListClose = true;
            this.well_change();
            //this.frml++;
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
      }
    },

    init_list() {
      this.tests_list = this.editedItem.wellstests
        ? this.editedItem.wellstests
        : [];
      if (
        this.tests_list.filter(
          (elm) => elm.testtype_id == 2 && elm.statut_id == 6
        ).length > 0
      ) {
        this.corrosions_list = this.tests_list[0].corrosion_details;
      }
      if (
        this.tests_list.filter(
          (elm) => elm.testtype_id == 8 && elm.statut_id == 6
        ).length > 0
      ) {
        this.cements_list = this.tests_list[0].cement_details;
      }
      this.events = this.editedItem.events ? this.editedItem.events : [];
      this.events_list = this.events.filter((elm) => elm.journals != null);
      this.tubulars_list = this.editedItem.wellstubulars
        ? this.editedItem.wellstubulars
        : [];
      this.annulus_list = this.editedItem.wellstubulars
        ? this.editedItem.wellstubulars.filter((elm) => elm.depthfrom == 0)
        : [];
      this.wells_evaluations = this.editedItem.wellsevaluations
        ? this.editedItem.wellsevaluations
        : [];
      this.formations_list = this.editedItem.wellsformations
        ? this.editedItem.wellsformations
        : [];
      this.productions_list = this.editedItem.productions
        ? this.editedItem.productions
        : [];
      this.components_list = this.editedItem.wellscomponents
        ? this.editedItem.wellscomponents
        : [];

      this.fingers_list = this.editedItem.fingers_list
        ? this.editedItem.fingers_list
        : [];
      //  this.tf++;
      this.prd_key++;
      this.ksvg2++;

      ////get svg
      this.get_svg();
    },
    tab_change() {
      this.ksvg1++;
    },

    sortBy(prop) {
      return function (a, b) {
        return a[prop] ? a[prop].localeCompare(b[prop]) : a[prop];
      };
    },

    frm_change() {
      if (this.tab0 == "1" && this.tab0 == "6") this.ksvg1++;
    },

    tubular_add(item) {
      this.tubular = item;
      this.well_change();
    },
    async well_change() {
      let r3 = await this.$requette(
        WELL,
        {
          TypeScope: parseInt(this.editedItem.id),
          CatScope: this.$store.state.fields,
        },
        "no-cache"
      );
      this.data_loading = false;
      if (r3.ok) {
        this.editedItem = r3.data.allwellsstates[0];
        this.init_list();
        this.tbl_key++;
        this.ann_key++;
        this.frm_key++;
        this.cmp_key++;
        this.event_key++;
        //
        this.$nextTick(() => {
          this.change = 2;
          this.ksvg1++;
        });
      }
    },

    FormationOpen(item) {
      this.formation = item;
      this.isFormationClose = false;
      this.fkey++;
    },
    FormationSelect(item) {
      this.formation = item;
    },
    SelectComponent(item) {
      this.component = item;
      if (this.component.compartment == null)
        this.components_detail = this.components_list.filter(
          (elm) => elm.compartment == this.component.code
        );
    },
    async ComponentDelete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete Component " + item.label + " !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(COMPONENT_LIST_DELETE, { id: item.id });
        if (r.ok) {
          this.well_change();

          this.ksvg1++;
          this.ksvg2++;
          this.get_svg();
        }
        this.data_loading = false;
      }
    },
    async FormationDelete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete Formation " + item.formationname + " !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(UPDATE_FORMATION, {
          wellsformation: { id: item.id, active: 0 },
        });
        if (r) {
          this.well_change();
          this.frm_key++;
        } else this.data_loading = false;
      }
    },
    async TubularDelete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete Tubular " + item.item + " !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(DELETE_TUBULAR, { id: item.id });
        if (r) {
          this.well_change();
          this.tbl_key++;
        } else this.data_loading = false;
      }
    },
    AnnulusOpen(item) {
      this.annulus = true;
      this.tubular = item;
      this.act_tublar = item;
      this.tubular.well_id = this.editedItem.id;
      this.tubular.max_id_in = this.editedItem.max_id_in;
      this.isTubularClose = false;
      this.tkey++;
    },
    TubularOpen(item) {
      this.annulus = false;
      this.tubular = item;
      this.tubular.well_id = this.editedItem.id;
      this.act_tublar = item;
      this.isTubularClose = false;
      this.tkey++;
    },
    component_change(item) {
      let i = this.components_detail.findIndex((elm) => elm.id == item.id);
      if (i >= 0) {
        this.components_detail[i] = item;
        this.exp_key++;
      }
    },
    close_cmpselect(items) {
      if (items) {
        this.well_change();
        this.get_svg(items);
      }
      this.isComponentselectClose = true;
    },
    ComponentOpen(item) {
      this.component = item;
      this.component.well_id = this.editedItem.id;
      this.act_component = item;
      if (this.component.compartment == null)
        this.components_detail = this.components_list.filter(
          (elm) => elm.compartment == this.component.code
        );
      this.isComponentselectClose = false;
      this.tcompselect++;
    },
    ComponentEdit(item) {
      this.component = item;
      this.component.well_id = this.editedItem.id;
      this.act_component = item;

      this.isComponentClose = false;
      this.tcomp++;
    },

    TubularSelect(item) {
      if (item) {
        this.tubular = item;
        this.apicasing_list = this.apicasing.filter(
          (elm) =>
            elm.csg_type == item.type &&
            (item.min_id ? elm.id_in > item.min_id : true) &&
            (item.max_od ? elm.od_in < item.max_od : true)
        );
      }
    },

    lib_change() {
      this.change++;
      this.lc++;
    },
    async label_valid() {
      this.lib = true;
      this.$refs.form.resetValidation();
      if (this.editedItem.wellname) {
        this.libv = true;
        let w = null;
        if (this.editedItem.id > 0)
          w = {
            AND: [
              {
                column: "WELLNAME",
                value: this.editedItem.wellname,
              },
              { column: "ID", operator: "NEQ", value: this.editedItem.id },
            ],
          };
        else
          w = {
            column: "WELLNAME",
            value: this.editedItem.wellname,
          };
        let v = {
          where: w,
        };
        let r = await this.$requette(ALLCODE, v);
        this.libv = false;
        if (r.ok) {
          if (r.data.allwells.length > 0) {
            this.lib = false;
            //  ok = false;
          }
        }
      }
      return this.$refs.form2.validate();
    },

    addFiles() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.files.click();
    },

    upload() {
      if (this.chosenFile) {
        if (this.chosenFile.size > 0) {
          this.$apollo
            .mutate({
              mutation: UPLOAD,
              variables: {
                file: this.chosenFile,
                image: true,
              },
              context: {
                hasUpload: true,
              },
            })
            .then((data) => {
              let d = JSON.parse(data.data.Upload);
              this.editedItem.url = d.url;
              this.uploading = false;
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_color = "error";
              this.snackbar_text = error.message;
              this.snackbar = true;
            });
        }
      }
    },
    ajax_add() {
      if (!this.uploading && this.$refs.files.files[0]) {
        this.chosenFile = this.$refs.files.files[0];
        if (
          this.chosenFile.size >
          this.$store.state.options[0].max_file_size * 1000000
        ) {
          this.snackbar_text =
            "Picture size upper than " +
            this.$store.state.options[0].max_file_size +
            " Mb";
          this.snackbar_color = "error";
          this.snackbar = true;
        } else {
          this.uploading = true;
          this.upload();
        }
      }
      return false;
    },
    delete_img() {
      this.editedItem.url = "no-image-icon";
    },

    async close() {
      let ok = true;
      if (this.change > 2) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Changes in-progress are you sur?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        if (this.$route.params.well) {
          this.$router.push({
            name: this.$route.params.route_source,
            params: {
              well: this.editedItem,
              viewlist: false,
            },
          });
        } else this.$emit("close");
      }
    },

    async validate() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Validate this well ! are-you sur?"
        )
      ) {
        this.editedItem.statut_id = "2";
        let v = {
          well: {
            id: this.editedItem.id,
            statut_id: this.editedItem.statut_id,
          },
        };
        await this.$maj(UPDATE_WELL, v);
        this.change = 2;
        this.$emit("refresh");
        this.$store.dispatch("Changed", true);
      }
    },

    async save() {
      this.tabs = "1";

      this.lib = true;
      if (this.$refs.form2.validate()) {
        let ok = true;
        if (this.editedItem.id < 0 || this.lc > 0)
          ok = await this.label_valid();

        if (ok) {
          let new_well = this.editedItem.id < 0;
          let v = {
            well: [
              {
                id: this.editedItem.id < 0 ? null : this.editedItem.id,
                wellname: this.editedItem.wellname,
                comment: this.editedItem.comment,
                td: this.editedItem.td,
                lifecycle: this.editedItem.lifecycle,
                welltype_id: this.editedItem.welltype_id,
                integrity_status: this.editedItem.integrity_status,
                statut_id: this.editedItem.statut_id,
                latitude: this.editedItem.latitude,
                longitude: this.editedItem.longitude,
                drillingdate: this.editedItem.drillingdate,
                field_id: this.field_id.toString(),
              },
            ],
            flags: this.selectedflags,
          };
          let r2 = await this.$maj(WELL_UPSERT, v);

          if (r2.ok) {
            this.editedItem.id = r2.data.UpsertWell;
            if (new_well) {
              let r3 = await this.$requette(
                WELL,
                {
                  TypeScope: parseInt(this.editedItem.id),
                  CatScope: this.$store.state.fields,
                },
                "no-cache"
              );
              if (r3.ok) {
                if (r3.data.allwellsstates.length > 0)
                  this.editedItem = r3.data.allwellsstates[0];
                this.init_list();
              }
            }
            this.$store.dispatch("Changed", true);
            this.$nextTick(() => {
              this.change = 2;
            });
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
      }
    },
    //ComponentOpen
    ComponentShow(item) {
      let index = this.components_list.findIndex((x) => x.id == item);
      this.component = this.components_list[index];
      this.component.well_id = this.editedItem.id;
      this.act_component = this.component;

      this.isComponentClose = false;
      this.tcomp++;
    },
    update_svg() {
      this.ksvg2++;
    },
    get_svg(list) {
      this.ksvg2++;
      this.well_svg = {};
      let lst = [];
      if (list) lst = list;
      else lst = this.components_list;
      //treecap
      if (lst.length > 0) {
        let treecap = lst.findIndex((x) => x.code == "4");
        if (treecap > 0)
          this.well_svg.gauge = {
            id: lst[treecap].id,
            code: "4",
            label: "TreeCap",
          };

        //addxtree_top

        let xtree_top = lst
          .sort((a, b) => b.code - a.code)
          .filter(
            (x) => x.group_id == 1 && x.orientation == "T" && x.code != "4"
          );
        if (lst.length > 0 || xtree_top.legth > 0)
          this.well_svg.Manifold = { id: 2000, code: "200", label: "Manifold" };
        let xtree_left = lst
          .sort((a, b) => b.code - a.code)
          .filter((x) => x.group_id == 1 && x.orientation == "L");
        let xtree_right = lst
          .sort((a, b) => b.code - a.code)
          .filter((x) => x.group_id == 1 && x.orientation == "R");
        let xtree = lst
          .sort((a, b) => b.code - a.code)
          .filter((x) => x.group_id == 1 && x.orientation == null);

        if (xtree_top.length > 0) this.well_svg.xtree_top = xtree_top;
        if (xtree_left.length > 0)
          this.well_svg.xtree_left = { components: xtree_left };
        if (xtree_right.length > 0)
          this.well_svg.xtree_right = { components: xtree_right };

        if (xtree.length > 0) this.well_svg.xtree = xtree;
        let k = this.tubulars_list.findIndex((x) => x.tubular_id == 1);
        if (k >= 0)
          this.well_svg.tubulars = {
            depth: this.tubulars_list[k].depth,
            components: lst.filter((x) => x.group_id == 13),
          };
        this.well_svg.Annulus = [];

        let annulus = lst.filter((x) => x.group_id == 2);

        let casings = this.tubulars_list
          .filter((x) => x.tubular_id != 1 && x.depthfrom == 0)
          .sort((a, b) => a.csg_order - b.csg_order);

        //
        let csg_order = 0;
        if (casings.length > 0) {
          casings.forEach((x) => {
            this.well_svg.Annulus.push({
              csg_order: csg_order + 1,
              depth: x.depth,
              components: annulus.filter((i) => i.csg_order == csg_order + 1),
            });
            csg_order++;
          });
        }
        //well head group si required

        //CSG
        let csgs = lst.filter((x) => x.group_id == 9);
        if (csgs.length > 0) this.well_svg.csgs = csgs;
        //tubing 30
        let t30 = lst.findIndex((x) => x.code == "30");
        if (t30 >= 0)
          this.well_svg.TUBING30 = {
            id: lst[t30].id,
            code: "30",
            label: "Tubing",
          };
        //tubing 31
        let t31 = lst.findIndex((x) => x.code == "31");
        if (t31 >= 0)
          this.well_svg.TUBING31 = {
            id: lst[t31].id,
            code: "31",
            label: "Tubing",
          };
        //DHSV
        let t32 = lst.findIndex((x) => x.code == "52");
        if (t32 >= 0)
          this.well_svg.TUBING32 = {
            id: lst[t32].id,
            code: "52",
            label: "DHSV",
          };
        //corrosion
        this.well_svg.corrosion_barriers = this.corrosions_list;
        //cements
        this.well_svg.cement_barriers = this.cements_list;
        //formations
        this.well_svg.formations = this.formations_list.filter(
          (elm) => elm.active == 1
        );
        this.dialog_svg = true;
        this.cmp_key++;
        this.ksvg2++;
      }
    },
  },
};
</script>

<style scoped>
div.my-tabs [role="tab"] {
  justify-content: flex-start;
  margin-right: 2em;
}
.font-weight-bold {
  font-weight: bold;
}
</style>
